import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Table from '../../../../../components/shared/table';
import { getMediaPlanVersions } from '../../../../../services/media-plan-service';
import UserDetails from '../../../../../components/shared/user';
import { useTheme } from '@emotion/react';
import Chip from '../../../../../components/shared/chip';
import { formatDate } from '../../../../../libs/date/format';
import { capitalize } from '../../../../../utils/string';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../../constants/app-constants';

const VersionHistoryTable = ({ handleSetMediaPlanVersion, mediaPlanDetails }) => {
  const versionHistoryTableRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const enqueueSnackbar = useEnqueueSnackbar();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getMediaPlanVersions({ mediaPlanId: mediaPlanDetails.id })
      .then(response => {
        const { data: versionHistoryData } = response.data;
        setRowData(versionHistoryData);
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => setIsLoading(false));
  }, [mediaPlanDetails.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleViewMediaPlanDetails = params => {
    handleSetMediaPlanVersion(params.data?.id);
  };

  const columns = useMemo(
    () => [
      {
        colId: 'name',
        headerName: 'Media Plan Name',
        field: 'name',
        sortable: false,
        minWidth: 210,
        width: 210,
        maxWidth: 210,
        cellStyle: () => ({
          cursor: 'pointer',
        }),
        valueFormatter: params => params.value ?? '-',
        onCellClicked: handleViewMediaPlanDetails,
      },
      {
        colId: 'status',
        headerName: 'Status',
        field: 'status',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        cellStyle: () => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        valueGetter: params =>
          mediaPlanDetails?.activeVersionId === params?.data?.id ? 'ACTIVE' : params?.data?.status,
        valueFormatter: params => capitalize(params.value),
        cellRenderer: params => {
          if (!params.value) return '-';
          let status = 'warning';
          switch (params.value) {
            case 'FINALIZED':
            case 'ACTIVE':
              status = 'success';
              break;
            default:
              break;
          }
          return <Chip status={status} label={params.valueFormatted} styles={{ margin: 'auto' }} />;
        },
      },

      {
        colId: 'version',
        headerName: 'Version',
        field: 'versionNumber',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        cellStyle: () => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        valueFormatter: params => params.value ?? '-',
      },
      {
        colId: 'planner',
        headerName: 'Planner',
        field: 'planner',
        minWidth: 195,
        width: 195,
        maxWidth: 195,
        cellRenderer: params => (
          <UserDetails
            fullName={params?.data?.planner}
            fullNameColor={theme.palette.text['100']}
            initials={params?.data?.planner.split(' ').map(x => x[0].toUpperCase())}
            initialsBackgroundColor={theme.palette.primary.main}
            initialsColor={theme.palette.white}
            initialsVariant="SemiBold-12"
            initailsContainer="32px"
            fullNameVariant="Medium-14"
          />
        ),
      },
      {
        colId: 'createdAt',
        headerName: 'Created On',
        field: 'createdAt',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        valueFormatter: params =>
          params?.value ? formatDate(new Date(params?.value), 'dd/MM/yyyy') : '-',
      },
      {
        colId: 'modifiedBy',
        headerName: 'Last Modified by',
        field: 'modifiedBy',
        minWidth: 195,
        width: 195,
        maxWidth: 195,
        cellRenderer: params =>
          params.value ? (
            <UserDetails
              fullName={params.value}
              fullNameColor={theme.palette.text['100']}
              initials={params.value?.split(' ').map(x => x[0].toUpperCase())}
              initialsBackgroundColor={theme.palette.primary.main}
              initialsColor={theme.palette.white}
              initialsVariant="SemiBold-12"
              initailsContainer="32px"
              fullNameVariant="Medium-14"
            />
          ) : (
            '-'
          ),
      },
      {
        colId: 'modifiedOn',
        headerName: 'Last Modified On',
        field: 'modifiedOn',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        valueFormatter: params =>
          params?.value ? formatDate(new Date(params?.value), 'dd/MM/yyyy') : '-',
      },
      {
        colId: 'mediaPlanBudget',
        headerName: 'Media Plan Budget',
        field: 'mediaPlanBudget',
        minWidth: 175,
        width: 175,
        maxWidth: 175,
        valueFormatter: params => params.value ?? '-',
      },
      {
        colId: 'givenBudget',
        headerName: 'Given Budget',
        field: 'givenBudget',
        minWidth: 155,
        width: 155,
        maxWidth: 155,
        valueFormatter: params => params.value ?? '-',
      },
      {
        colId: 'remarks',
        headerName: 'Remarks',
        field: 'remarks',
        minWidth: 400,
        width: 400,
        maxWidth: 400,
        valueFormatter: params => params.value ?? '-',
      },
    ],
    [mediaPlanDetails?.activeVersionId],
  );
  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );
  return (
    <Table
      ref={versionHistoryTableRef}
      isLoading={isLoading}
      columns={columns}
      defaultColDef={defaultColDef}
      data={rowData}
      height={`max(calc((${Math.min(rowData?.length ? rowData.length : 5, 5) * 3.75}rem) + 97px), calc(100vh - 535px))`}
    />
  );
};

export default VersionHistoryTable;
