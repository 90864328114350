import { Box, List, ListItem, ListSubheader } from '@mui/material';
import React, { useState, useCallback, forwardRef } from 'react';
import Input from '../Input/input';
import { debounce } from 'lodash';

const SearchableSelect = ({
  onOptionSearch,
  optionMapFunction,
  optionRenderer,
  placeholder = '',
  onOptionSelect,
  onSearchCloseClick,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOptionLoading, setIsOptionLoading] = useState(false);

  const optionSearch = useCallback(
    text => {
      if (onOptionSearch && !!text) {
        onOptionSearch(text).then(res => {
          setIsOptionLoading(false);
          const slicedResult = res?.slice(0, 50);
          setSearchedOptions(optionMapFunction ? optionMapFunction(slicedResult) : slicedResult);
        });
      }
    },
    [onOptionSearch, optionMapFunction],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOptionSearch = useCallback(debounce(optionSearch, 500), []);

  const handleOptionSearched = e => {
    const text = e.target.value;

    setSearchText(text);
    setIsOptionLoading(true);
    debouncedOptionSearch(text);
  };

  const handleSearchCloseClick = () => {
    setSearchText('');
    setSelectedOption(null);
    setIsOptionSelected(false);

    onSearchCloseClick();
  };

  const handleOptionSelect = option => {
    if (!option.bbgId) {
      setSelectedOption(option);
      setIsOptionSelected(true);
      setSearchText('');

      onOptionSelect(option.value);
    }
  };

  return (
    <List sx={{ p: 0, width: 'auto', maxHeight: '370px', position: 'relative' }}>
      <ListSubheader sx={{ backgroundColor: '#FFFFFF', position: 'sticky', py: 1, px: 0 }}>
        <Input
          value={isOptionSelected ? selectedOption?.value : searchText}
          inputHeight="40px"
          onChange={handleOptionSearched}
          onSearchCloseClick={handleSearchCloseClick}
          placeholder={placeholder}
          searchable
          type="text"
          disabled={isOptionSelected}
          sx={{ position: 'sticky' }}
          autoFocus
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
            if (e.key === 'Backspace') {
              setSelectedOption(null);
              setIsOptionSelected(false);
            }
          }}
        />
      </ListSubheader>

      {!!searchText ? (
        <Box
          sx={{
            position: 'absolute',
            top: '10',
            bottom: '10',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
            width: 'calc(100% - 2px)',
            maxHeight: '500px',
            overflowY: 'auto',
            zIndex: '1000',
            marginLeft: '1px',
            padding: '8px',
            boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)',
            '&::-webkit-scrollbar': {
              width: '7px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#CDD1D7',
              borderRadius: '4px',
            },
          }}
        >
          {isOptionLoading ? (
            <Box
              sx={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              Loading ...
            </Box>
          ) : searchedOptions?.length > 0 ? (
            searchedOptions?.map((option, index) => (
              <ListItem
                key={index}
                sx={{
                  cursor: option.bbgId ? 'not-allowed' : 'pointer',
                  padding: '8px 16px',
                  width: '100%',
                  height: '43px',
                  '&:hover': {
                    backgroundColor: '#F4F4F4',
                    borderRadius: '8px',
                  },
                  background: option.bbgId ? '#f4f4f4' : '',
                  borderRadius: '5px',
                }}
                onClick={() => handleOptionSelect(option)}
              >
                {optionRenderer ? optionRenderer(option) : option.label}
              </ListItem>
            ))
          ) : (
            <ListItem>No options found</ListItem>
          )}
        </Box>
      ) : null}
    </List>
  );
};

export default forwardRef(SearchableSelect);
