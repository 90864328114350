import RouteHandler from '../../../components/router/handler';
import VendorInvoiceList from './VendorInvoiceList';

const VendorInvoiceRoutes = [
  {
    index: true,
    path: '',
    component: <VendorInvoiceList />,
  },
  // {
  //   path: 'view/:id',
  //   component: <PoVendor />,
  // }
];

const VendorInvoiceRouter = () => {
  return <RouteHandler routes={VendorInvoiceRoutes} />;
};

export default VendorInvoiceRouter;
