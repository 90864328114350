// base http client axios instance
import axios from 'axios';
import { User } from 'oidc-client';

function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:https://auth-dev.mindshareapps.com/:pkce-aad-okta`,
  ); //oidc.user:<your authority>:<your client id>
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const renewToken = async () => {
  return await window.refreshToken();
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxRedirects: 0,
});

axiosInstance.interceptors.request.use(
  async config => {
    const user = getUser();
    if (user?.access_token) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await renewToken();

      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
