import React, { useState } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import Button from '../../../../../../components/shared/buttons';
import { EditIcon, InvoiceIcon, UploadIcon } from '../../../../../../libs/svg-icons/icons';
import Dialog from '../../../../../../components/shared/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../../../../../components/shared/form/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from '../../../../../../components/shared/form/date-picker';
import Select from '../../../../../../components/shared/form/Select';
import DragAndDropFilePicker from '../../../../../../components/shared/form/fileupload';
import Textarea from '../../../../../../components/shared/form/textarea';

const schema = yup.object().shape({
  invoicenumber: yup.string().required('Invoice Number is Required'),
  issuedate: yup.string().required('Issue Date is Required'),
  currency: yup.string().required('Currency is Required'),
  purchasenumber: yup.string().required('Purchase Number is Required'),
  purchaseorderdate: yup.string().required('Purchase Order Date Required'),
  startdate: yup.string().required('Start Date Required'),
  enddate: yup.string().required('End Date Required'),
  itemid: yup.string().required('Item Id Required'),
  quantity: yup.string().required('Quantity Required'),
  unit: yup.string().required('Unit Required'),
  priceperunit: yup.string().required('Price Per Unit Required'),
  tax: yup.string().required('Tax Required'),
  totaltax: yup.string().required('Total Tax Required'),
  taxnumber: yup.string().required('Tax Number Required'),
});

const ViewInvoiceEditDialog = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('view');
  const theme = useTheme();

  const handleOpenDialog = type => {
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const schemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const handleInvoiceUpload = file => {};

  const handleInvoiceRemoved = () => {
    schemaMethods.setValue('uploadinvoice', undefined, {
      shouldValidate: true,
    });
  };

  const details = [
    { label: 'Invoice Number', value: '0012973870284722' },
    { label: 'Issue Date', value: '01-08-2023' },
    { label: 'Currency', value: 'IDR' },
    { label: 'Purchase Order Number', value: 'PO567890' },
    { label: 'Purchase Order Issue Date', value: '24-09-2023' },
    { label: 'Start Date', value: '01-09-2023' },
    { label: 'End Date', value: '30-09-2023' },
  ];

  const popdetails = [
    { label: 'Item ID', value: '012345' },
    { label: 'Quantity', value: '1' },
    { label: 'Unit', value: 'pcs' },
    { label: 'Price Per Unit', value: '100,000,000' },
    { label: 'Tax %', value: '11%' },
    { label: 'Total Exclusive Tax', value: '7,480,000' },
    { label: 'Tax Number', value: '1234567890123456' },
  ];

  const totalDetails = [
    { label: 'Subtotal exclusive taxes', value: '68,000,000.00 IDR' },
    {
      label: 'ID VAT Standard 11% of 68,000,000.00 IDR (Valid from 01-08-2023)',
      value: '7,480,000.00 IDR',
    },
  ];

  const currencyOption = [
    { value: 'notContains', label: 'Does Not Contain' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Does Not Equal' },
  ];
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <Button
          label="View"
          styles={{ margin: 'auto' }}
          variant="outlined"
          height="30px"
          onClick={() => handleOpenDialog('view')}
        />

        <Button
          width="30px"
          height="30px"
          sx={{
            minWidth: '21px',
            padding: '5px',
          }}
          variant="outlined"
          startIcon={
            <Box
              component={'span'}
              sx={{
                width: '21px',
                height: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EditIcon fill={theme.palette.primary.main} />
            </Box>
          }
          onClick={() => handleOpenDialog('edit')}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        editButton={dialogType === 'view' ? true : false}
        headline={
          <Typography variant="SemiBold-22">
            {dialogType === 'view' ? 'View Invoice' : 'Edit Invoice'}
          </Typography>
        }
        description={
          dialogType === 'view' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '100%',
                flexDirection: 'column',
                gap: '40px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                {details?.map(detail => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '24px',
                      flexDirection: 'row',
                      width: '100%',
                      flex: '1 1 30%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '300px',
                        gap: '4px',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        marginTop: '16px',
                      }}
                    >
                      <Typography variant="Medium-16">{detail.label}</Typography>
                      <Typography variant="Regular-14">{detail.value}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Divider sx={{ width: '100%' }} />

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                {popdetails?.map(detail => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '24px',
                      flexDirection: 'row',
                      width: '100%',
                      flex: '1 1 30%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '300px',
                        gap: '4px',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        marginTop: '16px',
                      }}
                    >
                      <Typography variant="Medium-16">{detail.label}</Typography>
                      <Typography variant="Regular-14">{detail.value}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '191px',
                  borderRadius: '8px',
                  border: '1px solid #CDD1D7',
                  backgroundColor: '#F4F4F4',
                  padding: '16px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  {totalDetails?.map(detail => (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
                      <Box sx={{ display: 'flex', width: '665px' }}>
                        <Typography variant="Regular-18">{detail.label}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '170px',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Typography variant="Regular-18">{detail.value}</Typography>
                      </Box>
                    </Box>
                  ))}

                  <Divider sx={{ width: '100%' }} />

                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
                    <Box sx={{ display: 'flex', width: '665px' }}>
                      <Typography variant="Medium-18">Total IDR</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '170px',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant="Medium-18">75,480,000.00 IDR</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '242px',
                  height: '244px',
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #CDD1D7',
                  borderRadius: '8px',
                  gap: '80px',
                  padding: '24px 0px 0px 0px ',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '0px 24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100px',
                    height: '52px',
                  }}
                >
                  <InvoiceIcon fill="" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    border: '1px solid #CDD1D7',
                    backgroundColor: '#F4F4F4',
                    height: '88px',
                    width: '100%',
                    padding: '16px',
                    gap: '5px',
                  }}
                >
                  <Typography variant="Medium-16">Invoice</Typography>
                  <Typography variant="Regular-14">18th Aug, 2023 at 12:00 PM</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  gap: '4px',
                }}
              >
                <Typography variant="Medium-16">Comments</Typography>
                <Typography variant="Regular-14">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus ultrices
                  dui sit amet aliquam.
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '100%',
                flexDirection: 'column',
                gap: '40px',
              }}
            >
              <FormProvider {...schemaMethods}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '24px',
                  }}
                >
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="invoicenumber"
                      inputHeight="48px"
                      label="Invoice Number"
                      {...schemaMethods.register('invoicenumber')}
                    />
                  </Box>

                  <Box sx={{ width: '31.5%', textAlign: 'left' }}>
                    <DatePicker
                      id="issuedate"
                      label="Issue Date"
                      placeholder="Select date"
                      {...schemaMethods.register('issuedate')}
                      onChange={() => null}
                      views="MM/DD"
                    />
                  </Box>

                  <Box sx={{ width: '31.5%' }}>
                    <Select
                      id="currency"
                      label="currency"
                      options={currencyOption}
                      selectHeight="48px"
                      placeholder="Select currency"
                      {...schemaMethods.register('currency')}
                    />
                  </Box>
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="purchasenumber"
                      inputHeight="48px"
                      placeholder="Enter purchase order number"
                      label="Purchase Order Number"
                      {...schemaMethods.register('purchasenumber')}
                    />
                  </Box>

                  <Box sx={{ width: '31.5%', textAlign: 'left' }}>
                    <DatePicker
                      id="purchaseorderdate"
                      label="Purchase Order Issue Date"
                      placeholder="Select issue date"
                      {...schemaMethods.register('purchaseorderdate')}
                      onChange={() => null}
                      views="MM/DD"
                    />
                  </Box>
                  <Box sx={{ width: '31.5%', textAlign: 'left' }}>
                    <DatePicker
                      id="startdate"
                      label="Start Date"
                      placeholder="Select date"
                      {...schemaMethods.register('startdate')}
                      onChange={() => null}
                      views="MM/DD"
                    />
                  </Box>

                  <Box sx={{ width: '31.5%', textAlign: 'left' }}>
                    <DatePicker
                      id="enddate"
                      label="End Date"
                      placeholder="Select date"
                      {...schemaMethods.register('enddate')}
                      onChange={() => null}
                      views="MM/DD"
                    />
                  </Box>
                </Box>
                <Divider sx={{ width: '100%' }} />

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="itemid"
                      inputHeight="48px"
                      placeholder="Enter ID"
                      label="Item ID"
                      {...schemaMethods.register('itemid')}
                    />
                  </Box>
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="quantity"
                      inputHeight="48px"
                      placeholder="Enter quantity"
                      label="Quantity"
                      {...schemaMethods.register('quantity')}
                    />
                  </Box>
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="unit"
                      inputHeight="48px"
                      placeholder="Enter number of unit"
                      label="Unit"
                      {...schemaMethods.register('unit')}
                    />
                  </Box>
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="priceperunit"
                      inputHeight="48px"
                      placeholder="Enter price per unit"
                      label="Price Per Unit"
                      {...schemaMethods.register('priceperunit')}
                    />
                  </Box>

                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="tax"
                      inputHeight="48px"
                      placeholder="Select tax percentage"
                      label="Tax %"
                      {...schemaMethods.register('tax')}
                    />
                  </Box>
                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="totaltax"
                      inputHeight="48px"
                      placeholder="Enter total exclusive tax"
                      label="Total Exclusive Tax"
                      {...schemaMethods.register('totaltax')}
                    />
                  </Box>

                  <Box sx={{ width: '31.5%' }}>
                    <InputField
                      type="text"
                      id="taxnumber"
                      inputHeight="48px"
                      placeholder="Enter tax number"
                      label="Tax Number"
                      {...schemaMethods.register('taxnumber')}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: '200px',
                    borderRadius: '8px',
                    border: '1px solid #CDD1D7',
                    backgroundColor: '#F4F4F4',
                    padding: '16px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '20px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {totalDetails?.map(detail => (
                      <Box
                        key={detail.label}
                        sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                      >
                        <Box sx={{ display: 'flex', width: '70%' }}>
                          <Typography variant="Regular-18">{detail.label}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            width: '30%',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Typography variant="Regular-18">{detail.value}</Typography>
                        </Box>
                      </Box>
                    ))}

                    <Divider sx={{ width: '100%' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px', width: '100%' }}>
                      <Box sx={{ display: 'flex', width: '60%' }}>
                        <Typography variant="Medium-18">Total IDR</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '40%',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Typography variant="Medium-18">0.00 IDR</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '20px',
                  }}
                >
                  <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Typography
                      variant="Medium-16"
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      Upload Media Plan Template
                    </Typography>
                    <DragAndDropFilePicker
                      multi={false}
                      label="Upload Document"
                      icon={<UploadIcon fill={theme.palette.primary.main} />}
                      headline="Upload Document"
                      description="(Only .csv file, maximum 20MB file size)"
                      onUpload={handleInvoiceUpload}
                      onRemove={handleInvoiceRemoved}
                      acceptedfileExtensions={['csv']}
                      draggable={true}
                      maxFileSize={5}
                      {...schemaMethods.register('uploadinvoice')}
                    />
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Textarea
                      id="comments"
                      type="text"
                      rows={8}
                      label="Comments"
                      placeholder="Write your comment here.."
                      {...schemaMethods.register('comments')}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Button label="Save Changes" variant="contained" />
                </Box>
              </FormProvider>
            </Box>
          )
        }
        width="988px"
        showDialogActions={false}
        fullBodyScroll={true}
      />
    </>
  );
};

export default ViewInvoiceEditDialog;
