import React, { useMemo } from 'react';
import Table from '../../../../../../components/shared/table';
import { capitalize, formatCurrency } from '../../../../../../utils/string';
import Chip from '../../../../../../components/shared/chip';
import { AppConstants } from '../../../../../../constants/app-constants';
import { formatDate } from '../../../../../../libs/date/format';
import { useTheme } from '@mui/material/styles';

const PoClientTable = ({ buyingGroupDetails }) => {
  const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        id: 'clientPo',
        headerName: 'Client PO',
        field: 'clientPo',
        sortable: false,
        minWidth: 130,
      },
      {
        id: 'clientPoNumber',
        headerName: 'PO Client Number',
        field: 'clientPoNumber',
        minWidth: 200,
        sortable: false,
        cellRenderer: params => params.value ?? '-',
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.clientPoNumber &&
            buyingGroupDetails?.webappClientPO?.clientPoNumber !==
              buyingGroupDetails?.minderClientPO?.clientPoNumber
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },

      {
        id: 'clientPoAmount',
        headerName: 'PO Amount',
        field: 'clientPoAmount',
        minWidth: 186,
        sortable: false,
        valueFormatter: params => formatCurrency(params.value),
        cellRenderer: params => (params.valueFormatted ? `${params.valueFormatted} IDR` : '-'),
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.clientPoAmount &&
            buyingGroupDetails?.webappClientPO?.clientPoAmount !==
              buyingGroupDetails?.minderClientPO?.clientPoAmount
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },
      {
        id: 'campaign',
        headerName: 'PO Description',
        field: 'campaign.name',
        minWidth: 175,
        sortable: false,
        cellRenderer: params => params.value ?? '-',
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.campaign?.name &&
            buyingGroupDetails?.webappClientPO?.campaign?.name !==
              buyingGroupDetails?.minderClientPO?.campaign?.name
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },
      {
        id: 'productId',
        headerName: 'Product ID',
        field: 'productId',
        minWidth: 250,
        sortable: false,
        cellRenderer: params => params.value ?? '-',
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.productId &&
            buyingGroupDetails?.webappClientPO?.productId !==
              buyingGroupDetails?.minderClientPO?.productId
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },
      {
        id: 'startDate',
        headerName: 'Start Date',
        field: 'startDate',
        minWidth: 200,
        sortable: false,
        cellRenderer: params => (params.value ? formatDate(params.value, 'dd/MM/yyyy') : '-'),
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.startDate &&
            buyingGroupDetails?.webappClientPO?.startDate !==
              buyingGroupDetails?.minderClientPO?.startDate
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },
      {
        id: 'endDate',
        headerName: 'End Date',
        field: 'endDate',
        minWidth: 200,
        sortable: false,
        cellRenderer: params => (params.value ? formatDate(params.value, 'dd/MM/yyyy') : '-'),
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.endDate &&
            buyingGroupDetails?.webappClientPO?.endDate !==
              buyingGroupDetails?.minderClientPO?.endDate
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },
      {
        id: 'expiryDate',
        headerName: 'Expired Date',
        field: 'expiryDate',
        minWidth: 200,
        sortable: false,
        cellRenderer: params => (params.value ? formatDate(params.value, 'dd/MM/yyyy') : '-'),
        cellStyle: () => {
          if (
            buyingGroupDetails?.minderClientPO?.expiryDate &&
            buyingGroupDetails?.webappClientPO?.expiryDate !==
              buyingGroupDetails?.minderClientPO?.expiryDate
          )
            return {
              background: theme.palette.error.veryLight,
            };
          return {
            background: theme.palette.text['600'],
          };
        },
      },
      {
        id: 'status',
        headerName: 'Status',
        field: 'status',
        minWidth: 214,
        sortable: false,
        valueFormatter: params => capitalize(params.value),
        cellRenderer: params => {
          if (!params.value) return '-';
          let status = 'warning';
          switch (params.value) {
            case AppConstants.ACTIVE:
              status = 'success';
              break;
            default:
              break;
          }
          return <Chip status={status} label={params.valueFormatted} styles={{ margin: 'auto' }} />;
        },
      },
    ],
    [buyingGroupDetails, theme.palette.error.veryLight, theme.palette.text],
  );

  const rowData = useMemo(
    () => [
      {
        clientPo: 'Web App',
        clientPoNumber: buyingGroupDetails?.webappClientPO?.clientPoNumber ?? null,
        clientPoAmount: buyingGroupDetails?.webappClientPO?.clientPoAmount ?? null,
        campaign: buyingGroupDetails?.webappClientPO ? buyingGroupDetails?.campaign : null,
        productId: buyingGroupDetails?.webappClientPO
          ? `${buyingGroupDetails?.variantFinanceName?.externalId} - ${buyingGroupDetails?.variantFinanceName?.name}`
          : null,
        startDate: buyingGroupDetails?.webappClientPO?.startDate ?? null,
        endDate: buyingGroupDetails?.webappClientPO?.endDate ?? null,
        expiryDate: buyingGroupDetails?.webappClientPO?.expiryDate ?? null,
        status: buyingGroupDetails?.webappClientPO?.status ?? null,
      },
      {
        clientPo: 'Minder',
        clientPoNumber: buyingGroupDetails?.minderClientPO?.clientPoNumber ?? null,
        clientPoAmount: buyingGroupDetails?.minderClientPO?.clientPoAmount ?? null,
        campaign: buyingGroupDetails?.minderClientPO ? buyingGroupDetails?.campaign : null,
        productId: buyingGroupDetails?.minderClientPO
          ? `${buyingGroupDetails?.variantFinanceName?.externalId} - ${buyingGroupDetails?.variantFinanceName?.name}`
          : null,
        startDate: buyingGroupDetails?.minderClientPO?.startDate ?? null,
        endDate: buyingGroupDetails?.minderClientPO?.endDate ?? null,
        expiryDate: buyingGroupDetails?.minderClientPO?.expiryDate ?? null,
        status: buyingGroupDetails?.minderClientPO?.status ?? null,
      },
    ],
    [buyingGroupDetails],
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      editable: false,
      flex: 1,
      cellStyle: () => ({
        background: theme.palette.text['600'],
      }),
    }),
    [theme.palette.text],
  );

  return <Table columns={columns} data={rowData} defaultColDef={defaultColDef} height={218} />;
};

export default PoClientTable;
