import TextFilter from '../../../../../../components/shared/table/filters/TextFilter';
import SetFilter from '../../../../../../components/shared/table/filters/SetFilter';
import Chip from '../../../../../../components/shared/chip';
import UserDetails from '../../../../../../components/shared/user';
import LockUnlockMediaPlanRow from './LockUnlockMediaPlanRow';
import { capitalize } from '../../../../../../utils/string';
import { Box } from '@mui/material';

export const columns = [
  {
    colId: 'checkbox',
    cellRenderer: () => <Box sx={{ width: '26px' }}></Box>,
    cellClass: 'menu-utils',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    width: 75,
    resizable: false,
    suppressSizeToFit: true,
  },
  {
    id: 'lock',
    headerName: '',
    field: 'lock',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    pinned: 'left',
    lockPosition: true,
    resizable: false,
    cellRenderer: params => <LockUnlockMediaPlanRow {...params} />,
  },
  {
    colId: 'externalId',
    headerName: 'ID',
    field: 'externalId',
    sortable: true,
    minWidth: 200,
    width: 200,
    suppressSizeToFit: true,
  },
  {
    colId: 'category',
    headerName: 'Category',
    field: 'category.id',
    sortable: true,
    minWidth: 165,
    suppressSizeToFit: true,
    clipboard: false,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'brand',
    headerName: 'Brand',
    field: 'brand.id',
    sortable: true,
    minWidth: 191,
    suppressSizeToFit: true,
    clipboard: false,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'brandFinance',
    headerName: 'Brand Finance',
    field: 'brandFinance.id',
    minWidth: 191,
    suppressSizeToFit: true,
    clipboard: false,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'campaign',
    headerName: 'Campaign',
    field: 'campaign.id',
    minWidth: 200,
    suppressSizeToFit: true,
    clipboard: false,
    filter: SetFilter,
    suppressFiltersToolPanel: true,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'variantFinanceCode',
    headerName: 'Variant (Finance) Code',
    field: 'variantFinanceName.externalId',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'variantFinance',
    headerName: 'Variant (Finance) Name',
    field: 'variantFinanceName.id',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'material',
    headerName: 'Material Name',
    field: 'materialName.id',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'materialCode',
    headerName: 'Material Code',
    field: 'materialCode',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'clusterTA',
    headerName: 'Cluster TA',
    field: 'clusterTA.id',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'minderTA',
    headerName: 'Minder TA',
    field: 'minderTA.id',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    clipboard: false,
    filter: SetFilter,
    suppressFiltersToolPanel: true,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'media',
    headerName: 'Media',
    field: 'mediaType.id',
    sortable: true,
    minWidth: 108,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'format',
    headerName: 'Format',
    field: 'format.id',
    sortable: true,
    minWidth: 180,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'placement',
    headerName: 'Placement',
    field: 'placement.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'type',
    headerName: 'Type',
    field: 'type.id',
    sortable: true,
    minWidth: 181,
    suppressSizeToFit: true,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'partner',
    headerName: 'Partner',
    field: 'partner.id',
    sortable: true,
    minWidth: 226,
    suppressSizeToFit: true,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'taDigital',
    headerName: 'TA Digital',
    field: 'taDigital',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : 'NA'),
  },
  {
    colId: 'month',
    headerName: 'Month',
    field: 'month.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'quarter',
    headerName: 'Quarter',
    field: 'quarter.id',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'half',
    headerName: 'Half',
    field: 'half.id',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'frequency',
    headerName: 'Frequency',
    field: 'frequency.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'tvReach',
    headerName: 'TV Reach',
    field: 'tvReach',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'duration',
    headerName: 'Duration',
    field: 'duration.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'grp',
    headerName: 'GRP',
    field: 'grp',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'ngrp',
    headerName: 'NGRP',
    field: 'ngrp',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'ngrpReport',
    headerName: 'NGRP Report',
    field: 'ngrpReport',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'acd',
    headerName: 'ACD',
    field: 'acd',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'planBudget',
    headerName: 'Plan Budget',
    field: 'planBudget',
    sortable: true,
    minWidth: 158,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'ioNumber',
    headerName: 'IO Number',
    field: 'ioNumber',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => params.value ?? '-',
  },
  {
    colId: 'bbNumberMinder',
    headerName: 'BB Number Minder',
    field: 'doNumber',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => params.value ?? '-',
  },
  // {
  //   colId: 'doNumber',
  //   headerName: 'DO Number',
  //   field: 'doNumber',
  //   sortable: true,
  //   minWidth: 250,
  //   suppressSizeToFit: true,
  //   filter: TextFilter,
  // },
  {
    colId: 'notes',
    headerName: 'Notes',
    field: 'notes',
    sortable: true,
    minWidth: 300,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
  {
    colId: 'status',
    headerName: 'Status',
    field: 'mediaPlanRowsStatus',
    valueFormatter: params => capitalize(params.value),
    cellRenderer: params => {
      if (!params.value) return '-';
      let status = 'warning';
      switch (params.value) {
        case 'LIVE':
          status = 'success';
          break;
        case 'CANCELLED':
          status = 'error';
          break;
        default:
          break;
      }
      return <Chip status={status} label={params.valueFormatted} styles={{ margin: 'auto' }} />;
    },
    minWidth: 165,
    filter: SetFilter,
  },
  {
    colId: 'planer',
    headerName: 'Planner',
    field: 'plannerName',
    sortable: false,
    minWidth: 210,
    cellRenderer: params => (
      <UserDetails
        fullName={params?.value}
        fullNameColor="var(--text-100)"
        initials={params?.value?.split(' ').map(x => x[0])}
        initialsBackgroundColor="var(--primary)"
        initialsColor="var(--white)"
        initialsVariant="SemiBold-12"
        initailsContainer="32px"
        fullNameVariant="Medium-14"
      />
    ),
    filter: SetFilter,
  },
  {
    colId: 'expenditureType',
    headerName: 'CBT Code',
    field: 'expenditureType.externalId',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'activityInput',
    headerName: 'Activity Input',
    field: 'activityInput.id',
    sortable: true,
    minWidth: 270,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'jtbd',
    headerName: 'JTBD',
    field: 'jtbd',
    sortable: true,
    minWidth: 500,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
];

export const defaultColDef = {
  resizable: true,
  flex: 1,
  cellStyle: params => {
    let isCellMuted = false;
    if (params.data?.mediaPlanRowsStatus === 'CANCELLED') {
      isCellMuted = true;
    } else {
      isCellMuted = false;
    }
    return {
      background: isCellMuted ? 'var(--text-600)' : 'transparent',
      cursor: isCellMuted ? 'not-allowed' : 'pointer',
    };
  },
};
