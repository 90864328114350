import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Button from '../../../../../../components/shared/buttons';
import Chip from '../../../../../../components/shared/chip';
import Dialog from '../../../../../../components/shared/dialog';
import SendPoClientExceptionDialog from '../../../../../../components/shared/dialog/SendPoClientExceptionDialog';
import ClientPoViaGcpForm from '../../../../../../components/shared/dialog/ClientPoViaGcpForm';
import ViewPoExceptionRequestToClient from '../../../../../../components/shared/dialog/ViewPoExceptionRequestToClient';
import CLickableCard from '../../../../../../components/shared/clickable-card';
import { SendPoClientRequestIcon, UploadClientIcon } from '../../../../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useParams } from 'react-router-dom';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import { GenerateClientPOException } from '../../../../../../services/media-plan-service';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import Loader from '../../../../../../components/shared/loader';

const exceptionStatusEnum = {
  PO_CLIENT_EXCEPTION_REQUESTED: 'PO Client Exception Requested',
  PO_CLIENT_EXCEPTION_REQUEST_APPROVED: 'PO Client Exception Request Approved',
  PO_CLIENT_EXCEPTION_REJECTED: 'PO Client Exception Request Rejected',
};

const PoClientButtonDialog = ({
  toggleApprovalComponent,
  fetchBuyingBriefGroup = () => {},
  buyingGroupDetails = null,
  handleChangePoClientExceptionStatus = (a, b) => {},
  canCancelApproval = true,
  teamDetails,
  exceptionDetails,
  approvalStatus,
  requestStatus,
  clientConfirmationUrl,
  exceptionRequestDate,
  totalPlanBudget,
}) => {
  const theme = useTheme();
  const params = useParams();

  const hasAllPermissions = useHasAllPermission();
  const enqueueSnackbar = useEnqueueSnackbar();

  const [openSendPoClientRequest, setOpenSendPoClientRequest] = useState(false);
  const [openGetPoClientViaGcp, setOpenGetPoClientViaGcp] = useState(false);
  const [openSendPoCLientExceptionRequest, setOpenSendPoClientExceptionRequest] = useState(false);
  const [openViewPoRequestExceptionClient, setOpenViewPoRequestExceptionClient] = useState(false);
  const [showPoClientExceptionRequestChip, setShowPoClientExceptionRequestChip] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [exceptionData, setexceptionData] = useState(null);

  const {
    clientPoRequest = null,
    webappClientPO = null,
    downloadPath = '',
  } = buyingGroupDetails || {};

  const handleOpenSendPoClientRequest = () => {
    setOpenSendPoClientRequest(true);
  };
  const handleCloseSendPoClientRequest = () => {
    setOpenSendPoClientRequest(false);
  };
  const handleOpenGetPoClientViaGcp = () => {
    setOpenGetPoClientViaGcp(true);
    handleCloseSendPoClientRequest();
  };
  const handleCloseGetPoCLientViaGcp = () => {
    setOpenGetPoClientViaGcp(false);
  };
  const handleOpenSendPoClientExceptionRequest = () => {
    handleCloseSendPoClientRequest();
    GenerateExceptionRequest();
    // setOpenSendPoClientExceptionRequest(true);
  };
  const handleCloseSendPoClientExceptionRequest = () => {
    setOpenSendPoClientExceptionRequest(false);
  };

  const handleOpenViewPoClientExceptionRequest = () => {
    if (
      [
        AppConstants.PO_CLIENT_EXCEPTION_REQUESTED,
        AppConstants.PO_CLIENT_EXCEPTION_REQUEST_APPROVED,
        AppConstants.PO_CLIENT_EXCEPTION_REJECTED,
      ].includes(clientPoRequest?.requestStatus)
    )
      setOpenViewPoRequestExceptionClient(true);
  };
  const handleCloseViewPoClientExceptionRequest = () => {
    setOpenViewPoRequestExceptionClient(false);
  };

  const handleShowApproveAndReject = () => {
    handleCloseViewPoClientExceptionRequest();
    toggleApprovalComponent();
  };

  const GenerateExceptionRequest = async () => {
    setIsLoading(true);
    await GenerateClientPOException({
      'bbgId': params?.bgid,
    })
      .then(response => {
        const { data = null } = response?.data || {};
        if (data) {
          setexceptionData(data);
          setOpenSendPoClientExceptionRequest(true);
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
        handleCloseSendPoClientExceptionRequest();
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const cards = [
    <CLickableCard
      key={1}
      icon={<UploadClientIcon fill={theme.palette.primary.main} width="28px" height="28px" />}
      headline="Upload Client PO"
      description={
        <Typography variant="Regular-12">
          Upload the right Client PO associated with this buying group.{' '}
        </Typography>
      }
      onClick={handleOpenGetPoClientViaGcp}
    />,
    <CLickableCard
      key={2}
      icon={<SendPoClientRequestIcon fill={theme.palette.primary.main} />}
      headline="Send PO Client Exception Request"
      description={
        <Typography variant="Regular-12">
          Send exception request to finance team in order to approve PO manually.
        </Typography>
      }
      onClick={handleOpenSendPoClientExceptionRequest}
    />,
  ];

  const CancelRequest = () => {
    if (clientPoRequest) {
      handleChangePoClientExceptionStatus(clientPoRequest?.id, {
        'bbgId': params?.bgid,
        'status': 'CANCELLED',
      });
      handleCloseViewPoClientExceptionRequest();
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      {downloadPath && (
        <>
          {webappClientPO && !clientPoRequest && (
            <>
              <Chip
                status={'success'}
                label={'PO Client Received'}
                styles={{
                  height: '40px',
                }}
                onClick={null}
              />
              <Button
                variant="contained"
                label="Select Client PO"
                onClick={handleOpenGetPoClientViaGcp}
              />
            </>
          )}

          {clientPoRequest?.approvalStatus &&
            ![AppConstants.PO_CLIENT_EXCEPTION_NOT_INITIATED].includes(
              clientPoRequest?.requestStatus,
            ) && (
              <Chip
                status={
                  showPoClientExceptionRequestChip &&
                  clientPoRequest?.requestStatus ===
                    AppConstants.PO_CLIENT_EXCEPTION_REQUEST_APPROVED
                    ? 'success'
                    : 'warning'
                }
                label={exceptionStatusEnum[clientPoRequest?.requestStatus]}
                styles={{
                  height: '40px',
                }}
                onClick={handleOpenViewPoClientExceptionRequest}
              />
            )}

          {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) &&
            ((!webappClientPO && !clientPoRequest) ||
              [
                AppConstants.PO_CLIENT_EXCEPTION_REJECTED,
                AppConstants.PO_CLIENT_EXCEPTION_NOT_INITIATED,
              ].includes(clientPoRequest?.requestStatus)) && (
              <Button
                variant="contained"
                label="Client PO"
                onClick={handleOpenSendPoClientRequest}
              />
            )}

          {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) &&
            webappClientPO &&
            clientPoRequest &&
            clientPoRequest?.requestStatus === 'PO_CLIENT_EXCEPTION_REQUEST_APPROVED' && (
              <Button
                variant="contained"
                label="Select Client PO"
                onClick={handleOpenGetPoClientViaGcp}
              />
            )}
        </>
      )}

      {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) && (
        <Dialog
          open={openSendPoClientRequest}
          onClose={handleCloseSendPoClientRequest}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Send Client PO Request
            </Typography>
          }
          cards={cards}
          width="668px"
          backgroundColor="white"
          showDialogActions={false}
        />
      )}
      {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) && exceptionData && (
        <Dialog
          open={openSendPoCLientExceptionRequest}
          onClose={handleCloseSendPoClientExceptionRequest}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Sending PO Client Exception Request
            </Typography>
          }
          width="668px"
          description={
            <SendPoClientExceptionDialog
              setShowPoClientExceptionRequestChip={setShowPoClientExceptionRequestChip}
              handleCloseSendPoClientExceptionRequest={handleCloseSendPoClientExceptionRequest}
              fetchBuyingBriefGroup={fetchBuyingBriefGroup}
              exceptionData={exceptionData}
            />
          }
          descriptionStyle={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textAlign: 'left',
          }}
          showDialogActions={false}
        />
      )}

      {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) && (
        <Dialog
          open={openGetPoClientViaGcp}
          onClose={handleCloseGetPoCLientViaGcp}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Select Client PO
            </Typography>
          }
          width="668px"
          description={
            <ClientPoViaGcpForm
              totalPlanBudget={totalPlanBudget}
              handleCloseGetPoCLientViaGcp={handleCloseGetPoCLientViaGcp}
              fetchBuyingBriefGroup={fetchBuyingBriefGroup}
            />
          }
          descriptionStyle={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textAlign: 'left',
          }}
          showDialogActions={false}
          fullBodyScroll={true}
        />
      )}

      <Dialog
        open={openViewPoRequestExceptionClient}
        onClose={handleCloseViewPoClientExceptionRequest}
        headline={
          <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
            View PO Exception Request to Client
          </Typography>
        }
        description={
          <ViewPoExceptionRequestToClient
            requestStatus={requestStatus}
            teamDetails={teamDetails}
            exceptionDetails={exceptionDetails}
            clientConfirmationUrl={clientConfirmationUrl}
            exceptionRequestDate={exceptionRequestDate}
          />
        }
        descriptionStyle={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          textAlign: 'left',
          padding: '0px 24px',
        }}
        width="668px"
        backgroundColor="white"
        buttons={
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <Chip
              label={exceptionStatusEnum[requestStatus]}
              status={
                requestStatus === AppConstants.PO_CLIENT_EXCEPTION_REQUEST_APPROVED
                  ? 'success'
                  : 'warning'
              }
              onClick={handleShowApproveAndReject}
            />
            {[AppConstants.PO_CLIENT_EXCEPTION_REQUESTED].includes(requestStatus) && (
              <Button label="Cancel Request" variant="outlined" onClick={CancelRequest} />
            )}
          </Box>
        }
      />
    </>
  );
};

export default PoClientButtonDialog;
