import { useGridFilter } from 'ag-grid-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Input from '../../form/Input/input';
import Select from '../../form/Select/select';
import { PinIcon } from '../../../../libs/svg-icons/icons';

const FilterOptions = [
  { value: 'contains', label: 'Contains' },
  { value: 'notContains', label: 'Does Not Contain' },
  { value: 'equals', label: 'Equals' },
  { value: 'notEquals', label: 'Does Not Equal' },
  { value: 'startsWith', label: 'Begins With' },
  { value: 'endsWith', label: 'Ends With' },
  { value: 'blank', label: 'Blank' },
  { value: 'notBlank', label: 'Not Blank' },
];

const TextFilter = ({ getValue, model, onModelChange, valueFormatter, ...props }) => {
  const menuRef = useRef(null);
  const theme = useTheme();

  const [filterType, setFilterType] = useState(FilterOptions[0].value);
  const [filterValue, setFilterValue] = useState('');

  const [isColPinned, setIsColPinned] = useState(false);
  const colId = props.column.colId;

  useEffect(() => {
    const listener = event => {
      if (menuRef.current?.getMenuOpenState()) event.api.showColumnMenu(colId);
    };

    const column = props.api.getColumn(colId);
    column.addEventListener('menuVisibleChanged', listener);

    return () => column.removeEventListener('menuVisibleChanged', listener);
  }, [colId, props.api]);

  const handleSearchTextChange = e => {
    setFilterValue(e.target.value);
    onModelChange({
      type: filterType,
      value: e.target.value,
      default: !e.target.value,
    });
  };

  const handleSearchCloseClick = () => {
    setFilterValue('');
    onModelChange({
      type: filterType,
      value: null,
      default: true,
    });
  };

  const onFilterChanged = (type, value) => {
    onModelChange({
      type,
      value,
    });
  };

  const doesFilterPass = useCallback(
    ({ data, node }) => {
      const value = getValue(node);
      const formattedValue = valueFormatter ? valueFormatter(value) : value;
      console.log(getValue(node), valueFormatter, formattedValue);
      const valueInLowerCase = formattedValue?.toString()?.toLowerCase();
      const filterInLowerCase = model?.value?.toString().toLowerCase();
      const type = model?.type;

      if (
        !(type === FilterOptions[6].value || type === FilterOptions[7].value) &&
        !filterInLowerCase
      )
        return true;

      switch (type) {
        case FilterOptions[0].value:
          return valueInLowerCase.indexOf(filterInLowerCase) >= 0;
        case FilterOptions[1].value:
          return valueInLowerCase.indexOf(filterInLowerCase) === -1;
        case FilterOptions[2].value:
          return valueInLowerCase === filterInLowerCase;
        case FilterOptions[3].value:
          return valueInLowerCase !== filterInLowerCase;
        case FilterOptions[4].value:
          return valueInLowerCase.indexOf(filterInLowerCase) === 0;
        case FilterOptions[5].value:
          const idx = valueInLowerCase.lastIndexOf(filterInLowerCase);
          return idx >= 0 && idx === valueInLowerCase.length - filterInLowerCase.length;
        case FilterOptions[6].value:
          return !value;
        case FilterOptions[7].value:
          return !!value;
        default:
          return true;
      }
    },
    [getValue, model?.type, model?.value, valueFormatter],
  );

  const handleColumnPinClicked = () => {
    if (props?.api && colId) {
      if (isColPinned) {
        props.api.applyColumnState({ state: [{ colId, pinned: false }] });
        setIsColPinned(false);
      } else {
        props.api.applyColumnState({ state: [{ colId, pinned: true }] });
        setIsColPinned(true);
      }
      props.api.hidePopupMenu();
    }
  };

  useGridFilter({ doesFilterPass });

  return (
    <List sx={{ p: 1, pt: 0, width: '200px' }}>
      <ListItem
        sx={{ width: '100%', backgroundColor: '#FFFFFF', position: 'sticky', py: 0, px: 0, pt: 1 }}
      >
        <Select
          ref={menuRef}
          value={filterType}
          onChange={e => {
            setFilterType(e.target.value);
            onFilterChanged(e.target.value, filterValue);
          }}
          options={FilterOptions}
          selectHeight="36px"
          onSearchCloseClick={handleSearchCloseClick}
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
        />
      </ListItem>
      {filterType === FilterOptions[6].value || filterType === FilterOptions[7].value ? null : (
        <ListItem sx={{ backgroundColor: '#FFFFFF', position: 'sticky', py: 0, px: 0, pb: 1 }}>
          <Input
            value={filterValue}
            inputHeight="36px"
            onChange={handleSearchTextChange}
            onSearchCloseClick={handleSearchCloseClick}
            placeholder="Search here"
            searchable
            sx={{ position: 'sticky' }}
            autoFocus
            onKeyDown={e => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListItem>
      )}
      <Divider />

      <ListItem
        sx={{
          backgroundColor: '#FFFFFF',
          position: 'sticky',
          mt: 0.5,
          py: 1,
          px: 0,
          '&:hover': {
            backgroundColor: '#0A275629',
            borderRadius: '8px',
          },
        }}
        onClick={handleColumnPinClicked}
      >
        <Box
          sx={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            cursor: 'pointer',
            padding: '0 28px',
          }}
        >
          <PinIcon fill={theme} />
          {isColPinned ? 'Unpin' : 'Pin'} Column
        </Box>
      </ListItem>
    </List>
  );
};

export default TextFilter;
