export const capitalize = text => {
  return (text ?? '')
    ?.toLowerCase()
    ?.split(/[\s_]+/)
    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(' ');
};

export const removeUnderscore = text => {
  return text.split('_').join(' ');
};

export const formatCurrency = number => {
  try {
    const numStr = number.toString();
    const parts = numStr.split('.');
    let integerPart = parts[0];

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const formattedNumber = parts.length > 1 ? integerPart + '.' + parts[1] : integerPart;

    return formattedNumber;
  } catch {
    return null;
  }
};
