import ViewInvoiceCardsDialogs from './ViewInvoiceCardsDailogs';
import ViewInvoiceEditDialog from './ViewInvoiceEditDialog';

export const columns = [
  {
    id: 'invoicenumber',
    headerName: 'Invoice Number',
    field: 'invoicenumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'invoice',
    headerName: 'Invoice',
    field: 'invoice',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      return <ViewInvoiceEditDialog />;
    },
  },
  {
    id: 'clientpo',
    headerName: 'Client PO',
    field: 'clientpo',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'buyingbriefgroupid',
    headerName: 'Buying Brief Group ID',
    field: 'buyingbriefgroupid',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'buyingbriefnumber',
    headerName: 'Buying Brief Number',
    field: 'buyingbriefnumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'issuedate',
    headerName: 'Issue date',
    field: 'issuedate',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'currency',
    headerName: 'Currency',
    field: 'currency',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'ponumber',
    headerName: 'PO Number',
    field: 'ponumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'poorderissuedate',
    headerName: 'PO Order Issue Date',
    field: 'poorderissuedate',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'itemid',
    headerName: 'Item ID',
    field: 'itemid',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'quantity',
    headerName: 'Quantity',
    field: 'quantity',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'unit',
    headerName: 'Unit',
    field: 'unit',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'priceperunit',
    headerName: 'Price Per Unit',
    field: 'priceperunit',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'tax',
    headerName: 'Tax %',
    field: 'tax',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'totalexculsivetax',
    headerName: 'Total Exculsive Tax',
    field: 'totalexculsivetax',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'taxnumber',
    headerName: 'Tax Number',
    field: 'taxnumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'subtotalexculsivetaxes',
    headerName: 'Subtotal Exculsive Taxes',
    field: 'subtotalexculsivetaxes',
    minWidth: 250,
    maxWidth: 250,
  },

  {
    id: 'idvat',
    headerName: 'ID VAT Standard 11%',
    field: 'idvat',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'totalidr',
    headerName: 'Total IDR',
    field: 'totalidr',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'comments',
    headerName: 'Comments',
    field: 'comments',
    minWidth: 400,
    maxWidth: 400,
  },
  {
    id: 'taxx',
    headerName: 'Tax',
    field: 'taxx',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      const items = [
        { title: 'Tax 1', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Tax 2', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Tax 3', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Tax 4', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Tax 5', content: '18th Aug, 2023 at 12:00 PM' },
      ];
      return <ViewInvoiceCardsDialogs headline="View Tax Documents" cards={items} />;
    },
  },
  {
    id: 'logproof',
    headerName: 'Log Proof/Proof of Airing',
    field: 'logproof',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      const items = [
        { title: 'Proof 1', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Proof 2', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Proof 3', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Proof 4', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Proof 5', content: '18th Aug, 2023 at 12:00 PM' },
      ];
      return <ViewInvoiceCardsDialogs headline="View Log Proof Documents" cards={items} />;
    },
  },
  {
    id: 'otherdocumnets',
    headerName: 'Other Documents',
    field: 'otherdocumnets',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      const items = [
        { title: 'Document 1', content: '18th Aug, 2023 at 12:00 PM' },
        { title: 'Document 2', content: '18th Aug, 2023 at 12:00 PM' },
      ];
      return <ViewInvoiceCardsDialogs headline="View Other Documents" cards={items} />;
    },
  },
];

export const data = [
  {
    id: 1,
    invoicenumber: '0012973870284722',
    invoice: 'View',
    clientpo: '01-08-2023',
    buyingbriefgroupid: 'BB01',
    buyingbriefnumber: 'BB347854907',
    issuedate: '01-08-2023',
    currency: 'IDR',
    ponumber: 'PO567890',
    poorderissuedate: '29-09-2023',
    itemid: '012345',
    quantity: '100,000,000',
    unit: 'pcs',
    priceperunit: '1',
    tax: '11%',
    totalexculsivetax: '7,480,000',
    taxnumber: '12345678901234567',
    subtotalexculsivetaxes: '68,000,000.00',
    idvat: '7,480,000.00 IDR',
    totalidr: '7,480,000.00 IDR',
    comments: 'Nulla  faucibus utrics faucibus utrics',
    taxx: 'View',
    logproof: 'View',
    otherdocumnets: 'View',
  },
];
